<template>
  <PageHeader
    image="header_12"
    withMobile
    :title="$lang('title')"
    titleClass="text-center mx-auto"
    class="justify-content-end pb-md-6"
  />
  <section class="py-8 bg-blue4">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <h2 class="fw-bold mb-5 text-blue2">{{ $lang("subtitle") }}</h2>

          <div class="card card-white">
            <div class="card-body">
              <h3 class="mb-4">{{ $lang("card.title") }}</h3>

              <p class="fw-bold mb-3 text-black3" v-html="parseCardBody($lang('card.body'))"></p>

              <div class="btn-group justify-content-center gap-x2">
                <a
                  href="https://www.instagram.com/nedcollege/"
                  target="_blank"
                  class="btn btn-circle btn-blue2"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
                <a
                  href="https://www.facebook.com/NEDCollege/"
                  target="_blank"
                  class="btn btn-circle btn-blue2"
                >
                  <i class="fa-brands fa-facebook"></i>
                </a>
                <a
                  href="https://www.youtube.com/user/nedtcschool"
                  target="_blank"
                  class="btn btn-circle btn-blue2"
                >
                  <i class="fa-brands fa-youtube"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/nedcollege"
                  target="_blank"
                  class="btn btn-circle btn-blue2"
                >
                  <i class="fa-brands fa-linkedin"></i>
                </a>
                <a
                  href="https://www.tiktok.com/@nedcollege"
                  target="_blank"
                  class="btn btn-circle btn-blue2"
                >
                  <i class="fa-brands fa-tiktok"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader,
  },
  methods: {
    parseCardBody(text) {
      const aboutUsLink = `<a href='/about_us/ned_college' class="text-blue2">about us</a>`;
      const dublinLink = `<a class="text-blue2" href='/our_campuses/dublin'>Dublin</a>`;
      const limerickLink = `<a class="text-blue2" href='/our_campuses/limerick'>Limerick</a>`;

      return text
        .replace("about us", aboutUsLink)
        .replace("Dublin", dublinLink)
        .replace("Limerick", limerickLink);
    },
  },
};
</script>
